import React, { createContext, useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Nav, Navbar } from 'react-bootstrap';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { notify } from '../../../components/Toast/Toast';
import { useProjectService } from '../../../services/ProjectsService';
import { MCHammer } from '../../../components/Loader';
import useAuth from '../../../hooks/useAuth';
import { IProjectHeaderContent, ProjectToSwitchContent } from './models';
import styled from 'styled-components';
import classNames from 'classnames';
import { AppleIcon } from '../../../components/AppleIcon/AppleIcon';
import { AndroidIcon } from '../../../components/AndroidIcon/AndroidIcon';
import DropdownItem from 'react-bootstrap/DropdownItem';
import {
  isDesktop,
  PERMISSION_CPI,
  PERMISSION_MONETIZATION,
  PERMISSION_NOTIFICATIONS,
  PERMISSION_PROJECT_SETTINGS,
  PERMISSION_READ_EXPERIMENTS,
  PERMISSION_SAYKIT_SETTINGS,
  PERMISSION_SUPPORT,
  prettifyName,
  SecondaryColor,
} from '../../../services/_utils';
import {
  filterProjectsToSwitch,
  makeHeaderProjectsFilterField,
} from './_utils';
import { ChinaIcon } from '../../../components/ChinaIcon/ChinaIcon';
import { BrowserChrome, PcDisplay } from 'react-bootstrap-icons';
import { SearchInputComponent } from '../../../components/SearchInput';

export const ProjectCtx = createContext({} as IProjectHeaderContent);

const ProjectHeader: React.FC = () => {
  const { projectId, versionId } = useParams();
  const { pathname } = useLocation();
  const { hasPermission, isAdmin } = useAuth();
  const { loading, error, getProjectHeader } = useProjectService();

  const [projectHeader, setProjectHeader] =
    useState<IProjectHeaderContent | null>();
  const [projectsToSwitch, setProjectsToSwitch] = useState(
    [] as ProjectToSwitchContent[]
  );
  const [projectSwitcherSearch, setProjectSwitcherSearch] = useState('');
  const [hoverStoreLink, setHoverStoreLink] = useState(false);

  useEffect(() => {}, [isDesktop()]);

  useEffect(() => {
    setProjectHeader(null);
    setProjectSwitcherSearch('');
    getProjectHeader(projectId!).then((resp) => {
      setProjectHeader(resp.header);
      setProjectsToSwitch(makeHeaderProjectsFilterField(resp.projectsToSwitch));
    });
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    error && notify('error', error);
  }, [error]);

  const mainClassname = classNames({
    'container-xxl':
      !pathname.includes('/compare-versions') &&
      !pathname.includes('/device_logs'),
    'container-fluid':
      pathname.includes('/compare-versions') ||
      pathname.includes('/device_logs'),
  });

  return (
    <>
      {loading && !projectHeader && <MCHammer />}
      {projectHeader && (
        <div className={mainClassname}>
          <StyledGameSwitcher>
            <div className="d-flex align-items-start">
              <DropdownButton
                data-testid="ddlProjectHeaderGameSwitcher"
                className="selectableDropdown d-inline-block"
                variant="outline-secondary"
                title={
                  <h2 className="d-flex align-items-center m-0 pe-2">
                    <img
                      className="appIcon me-2 ms-1"
                      src={`https://storeapi.saygames.io/api/v1/icon?appkey=${
                        projectHeader.appKey
                      }&return_empty_on_error=true&size=small${
                        projectHeader.china ? '&country=cn' : ''
                      }`}
                      alt={`${projectHeader.appKey}_icon`}
                      height={36}
                      width={36}
                    />
                    <div className="me-1">
                      {isDesktop() ? (
                        projectHeader.name
                      ) : (
                        <div>
                          {prettifyName(projectHeader.name).length > 20 ? (
                            <>
                              {prettifyName(projectHeader.name).substring(
                                0,
                                20
                              )}
                              ...
                            </>
                          ) : (
                            <>{prettifyName(projectHeader.name)}</>
                          )}
                        </div>
                      )}
                    </div>
                    {projectHeader.platform === 'pc' && (
                      <PcDisplay
                        className="ms-2"
                        size={25}
                        color={SecondaryColor}
                      />
                    )}
                    {projectHeader.platform === 'ios' && (
                      <AppleIcon className="" size={28} />
                    )}
                    {projectHeader.platform === 'android' && (
                      <AndroidIcon className="" size={32} />
                    )}
                    {projectHeader.platform === 'web' && (
                      <BrowserChrome
                        className=""
                        size={26}
                        color={SecondaryColor}
                      />
                    )}
                    {projectHeader.china && (
                      <ChinaIcon className="" size={32} />
                    )}
                  </h2>
                }
              >
                {projectsToSwitch
                  .filter((p) => projectHeader?.gamePack.includes(p.appKey))
                  .map((p) => (
                    <div key={p.id}>
                      <DropdownItem
                        data-testid={`lnkProjectHeaderGameSwitcher${p.id}`}
                        as={Link}
                        to={`/projects/${p.id}`}
                        className="d-inline-flex align-items-center"
                      >
                        <img
                          className="appIcon me-2"
                          src={`https://storeapi.saygames.io/api/v1/icon?appkey=${
                            p.appKey
                          }&return_empty_on_error=true&size=small${
                            p.china ? '&country=cn' : ''
                          }`}
                          alt={`${p.appKey}_icon`}
                          loading="lazy"
                          height={28}
                          width={28}
                        />
                        {prettifyName(p.name)}
                        {p.platform === 'ios' && (
                          <AppleIcon className="ms-1" size={20} />
                        )}
                        {p.platform === 'android' && (
                          <AndroidIcon className="ms-1" size={20} />
                        )}
                        {p.platform === 'web' && (
                          <BrowserChrome
                            className="ms-1"
                            size={15}
                            color={SecondaryColor}
                          />
                        )}
                        {p.china && <ChinaIcon className="ms-1" size={20} />}
                      </DropdownItem>
                    </div>
                  ))}
                <Dropdown.Divider
                  style={{ color: '#c1c1c1', border: 'none' }}
                />
                <div className="position-relative">
                  <div className="searchArea px-3">
                    <SearchInputComponent
                      setValue={setProjectSwitcherSearch}
                      datatestid={'txtProjectHeaderGameSwitcherSearch'}
                    />
                  </div>

                  <div
                    style={{
                      overflowY: 'scroll',
                      minHeight: 60,
                      maxHeight: 450,
                    }}
                  >
                    {filterProjectsToSwitch(
                      projectsToSwitch.filter(
                        (p) => !projectHeader?.gamePack.includes(p.appKey)
                      ),
                      projectSwitcherSearch
                    ).map((p, index) => (
                      <div
                        key={p.id}
                        className={index === 0 ? 'firstElemPadding' : ''}
                      >
                        <DropdownItem
                          data-testid={`lnkProjectHeaderGameSwitcher${p.id}`}
                          as={Link}
                          to={`/projects/${p.id}`}
                          className="d-inline-flex align-items-center"
                        >
                          <img
                            className="appIcon me-2"
                            src={`https://storeapi.saygames.io/api/v1/icon?appkey=${
                              p.appKey
                            }&return_empty_on_error=true&size=small${
                              p.china ? '&country=cn' : ''
                            }`}
                            alt={`${p.appKey}_icon`}
                            loading="lazy"
                            height={28}
                            width={28}
                          />
                          <div className="text-break d-flex align-items-center">
                            {prettifyName(p.name)}
                            {p.platform === 'ios' && (
                              <AppleIcon className="ms-1" size={20} />
                            )}
                            {p.platform === 'android' && (
                              <AndroidIcon className="ms-1" size={20} />
                            )}
                            {p.platform === 'web' && (
                              <BrowserChrome
                                className="ms-1"
                                size={15}
                                color={SecondaryColor}
                              />
                            )}
                            {p.china && <ChinaIcon className="" size={20} />}
                          </div>
                        </DropdownItem>
                      </div>
                    ))}
                  </div>
                </div>
              </DropdownButton>
            </div>
            <div
              className="additionalInfoSection"
              onMouseOver={() => setHoverStoreLink(true)}
              onMouseLeave={() => setHoverStoreLink(false)}
            >
              <span className="text-muted ms-3">{projectHeader.appKey}</span>
              <span className="text-muted ms-3">{projectHeader.company}</span>
              {projectHeader.storeUrl !== '' && (
                <a
                  data-testid="lnkProjectHeaderGameSwitcherStoreUrl"
                  className="d-flex align-items-center ms-3 text-decoration-none"
                  href={projectHeader.storeUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  {projectHeader.platform === 'ios' && (
                    <StyledStoreImg
                      src="/img/app_store_logo.png"
                      alt="app_store_logo"
                      height={22}
                      width={22}
                      hover={hoverStoreLink}
                    />
                  )}
                  {projectHeader.platform === 'android' &&
                    projectHeader.storeUrl.includes('play.google.com') && (
                      <StyledStoreImg
                        src="/img/play_google_logo.png"
                        alt="play_google_logo"
                        height={22}
                        width={22}
                        hover={hoverStoreLink}
                      />
                    )}
                  {projectHeader.platform === 'android' &&
                    projectHeader.storeUrl.includes('amazon') && (
                      <StyledStoreImg
                        src="/img/amazon_store_icon.png"
                        alt="amazon_store_logo"
                        height={22}
                        width={22}
                        hover={hoverStoreLink}
                      />
                    )}
                  {projectHeader.platform === 'android' &&
                    projectHeader.storeUrl.includes('huawei') && (
                      <StyledStoreImg
                        src="/img/huawei_store_icon.png"
                        alt="huawei_store_logo"
                        height={22}
                        width={22}
                        hover={hoverStoreLink}
                      />
                    )}
                  <span className="text-muted ms-2">Store page</span>
                </a>
              )}
            </div>
          </StyledGameSwitcher>
          <StyledNavbar
            className="mt-3 d-inline-block"
            isStyledBorder={versionId !== undefined ? 1 : 0}
          >
            <Navbar
              collapseOnSelect={true}
              expand="lg"
              className="navbar-light"
            >
              <StyledNav as={Nav}>
                <Navbar.Toggle aria-controls="project-navbar-nav" />
                <Navbar.Collapse id="project-navbar-nav">
                  <Nav.Link
                    as={Link}
                    eventKey="1"
                    data-testid="lnkProjectHeaderVersions"
                    to={``}
                    active={pathname === `/projects/${projectId}`}
                  >
                    Versions
                  </Nav.Link>
                  {hasPermission(PERMISSION_READ_EXPERIMENTS) &&
                    projectHeader.experiments && (
                      <Nav.Link
                        as={Link}
                        eventKey="1"
                        data-testid="lnkProjectHeaderExperiments"
                        to={`experiments`}
                        active={pathname.includes(
                          `/projects/${projectId}/experiments`
                        )}
                      >
                        Experiments
                      </Nav.Link>
                    )}
                  {projectHeader.globalLocalization && (
                    <Nav.Link
                      data-testid="lnkProjectHeaderLocalizations"
                      as={Link}
                      eventKey="2"
                      to={`game_messages`}
                      active={
                        pathname === `/projects/${projectId}/game_messages`
                      }
                    >
                      Localization
                    </Nav.Link>
                  )}
                  <Nav.Link
                    as={Link}
                    eventKey="3"
                    data-testid="lnkProjectHeaderDevices"
                    to={`devices`}
                    active={pathname === `/projects/${projectId}/devices`}
                  >
                    Devices
                  </Nav.Link>
                  {hasPermission(PERMISSION_SAYKIT_SETTINGS) && (
                    <Nav.Link
                      as={Link}
                      eventKey="4"
                      data-testid="lnkProjectHeaderSayKitSettings"
                      to={`saykit-settings`}
                      active={pathname.includes('/saykit-settings')}
                    >
                      SayKit
                    </Nav.Link>
                  )}
                  <Nav.Link
                    as={Link}
                    eventKey="5"
                    data-testid="lnkProjectHeaderBuilds"
                    to={`builds`}
                    active={pathname.includes('/builds')}
                  >
                    Builds
                  </Nav.Link>
                  {hasPermission(PERMISSION_PROJECT_SETTINGS) && (
                    <Nav.Link
                      as={Link}
                      eventKey="6"
                      data-testid="lnkProjectHeaderSettings"
                      to={`settings`}
                      active={pathname.includes('/settings')}
                    >
                      Settings
                    </Nav.Link>
                  )}
                  {hasPermission(PERMISSION_MONETIZATION) && (
                    <Nav.Link
                      as={Link}
                      eventKey="7"
                      data-testid="lnkProjectHeaderMonetization"
                      to={`monetization`}
                      active={pathname.includes('/monetization')}
                    >
                      Monetization
                    </Nav.Link>
                  )}
                  {hasPermission(PERMISSION_CPI) && (
                    <Nav.Link
                      as={Link}
                      eventKey="8"
                      data-testid="lnkProjectHeaderCPI"
                      to={`cpi`}
                      active={pathname.includes('/cpi')}
                    >
                      CPIs
                    </Nav.Link>
                  )}
                  {hasPermission(PERMISSION_SUPPORT) && (
                    <Nav.Link
                      as={Link}
                      eventKey="9"
                      data-testid="lnkProjectHeaderSupport"
                      to={`support`}
                      active={pathname.includes('/support')}
                    >
                      Support
                    </Nav.Link>
                  )}
                  {hasPermission(PERMISSION_NOTIFICATIONS) && (
                    <Nav.Link
                      as={Link}
                      eventKey="10"
                      data-testid="lnkProjectHeaderNotifications"
                      to={`notifications`}
                      active={pathname.includes('/notifications')}
                    >
                      Notifications
                    </Nav.Link>
                  )}
                  <Nav.Link
                    as={Link}
                    eventKey="11"
                    data-testid="lnkProjectHeaderUserIssues"
                    to={'user-issues'}
                    active={pathname.includes('/user-issues')}
                  >
                    User Issues
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    eventKey="11"
                    data-testid="lnkProjectHeaderLoggingCheck"
                    to={'log-validation'}
                    active={
                      pathname === `/projects/${projectId}/log-validation`
                    }
                  >
                    Log Validation
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    eventKey="12"
                    data-testid="lnkProjectHeaderInapps"
                    to={'inapps'}
                    active={pathname === `/projects/${projectId}/inapps`}
                  >
                    Inapps
                  </Nav.Link>
                </Navbar.Collapse>
              </StyledNav>
            </Navbar>
          </StyledNavbar>
          <ProjectCtx.Provider value={projectHeader}>
            <Outlet />
          </ProjectCtx.Provider>
        </div>
      )}
    </>
  );
};

export default ProjectHeader;

const StyledNavbar = styled.div<{ isStyledBorder: number }>`
  background: #f0f2f4;
  border-radius: ${(props: { isStyledBorder: number }) =>
    props.isStyledBorder === 1 ? '16px 16px 16px 0px' : '16px 16px 16px 16px'};

  .navbar-nav {
    padding-left: 1rem;
    padding-right: 1rem;

    animation: slideInFromLeft 1s ease;
  }

  .navbar-collapse.collapsing {
    background-color: #f0f2f4;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .navbar-collapse.collapse {
    background-color: #f0f2f4;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    z-index: 1;
  }
`;

const StyledNav = styled.nav`
  a {
    padding-top: 0;
    padding-bottom: 0;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledGameSwitcher = styled.div`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    .additionalInfoSection {
      display: flex;
      align-items: center;
      margin-left: 2.2rem;
      height: 20px;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;

    .additionalInfoSection {
      display: flex;
      align-items: center;
      height: 45px;
    }
  }

  .appIcon {
    border-radius: 10px;
  }

  .selectableDropdown {
    .btn-outline-secondary {
      background-color: #fff;
      color: black;
      height: 45px;
      border: 1px transparent;
      border-radius: 10px;
      padding: 0 10px 0 0;
      box-shadow: none;

      &:hover {
        background-color: #e9ecef;
      }
    }

    @keyframes slideIn {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 530px;
        opacity: 1;
      }
    }

    .dropdown-menu {
      animation: slideIn 0.5s ease;
      max-height: 530px;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
      border-radius: 8px;
      width: 100%;
      overflow-y: hidden;
    }

    .dropdown-toggle {
      border: none;
    }

    .dropdown-toggle::after {
      position: absolute;
      right: 5px;
      top: 22px;
    }
  }

  .searchArea {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: -2px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
  }

  .firstElemPadding {
    padding-top: 60px;
  }
`;

const StyledStoreImg = styled.img<{ hover: boolean }>`
  margin-left: 0.5rem;
  filter: ${(props) => (props.hover ? 'none' : 'grayscale(100%)')};
`;
